import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import { StaticEventOccurrenceInput } from '../../generated/graphql'
import DH from '../../helpers/DH'

interface Props {
  allDay?: boolean
  dates: StaticEventOccurrenceInput[]
  defaultDate?: number
  minDate: number

  onEdit(events: StaticEventOccurrenceInput[]): void
}

const useStyles = makeStyles()((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
      position: 'relative',
      minWidth: '340px',
      [theme.breakpoints.down('md')]: {
        maxWidth: 'auto',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '480px',
      },
    },
    fab: {
      display: 'flex',
      textAlign: 'center',
    },
    listItem: {
      marginBottom: theme.spacing(2),
    },
    picker: {
      display: 'flex',
      verticalAlign: 'middle',
    },
  }
})

const StaticRecurrenceDatesTable: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { allDay, dates: recurrenceDates, minDate, onEdit } = props
  const defaultDate = props.defaultDate ?? minDate
  const [errorMinDate, setErrorMinDate] = useState<boolean>(false)

  const onUpdate = useCallback(
    (idx?: number, date?: number) => {
      if (idx === undefined) {
        // Add date
        recurrenceDates.push({ start: defaultDate })
      } else if (date === undefined) {
        // Delete date
        recurrenceDates.splice(idx, 1)
      } else {
        // Edit date
        recurrenceDates[idx].start = date
      }
      // Update any change
      onEdit(recurrenceDates)
    },
    [defaultDate, onEdit, recurrenceDates],
  )

  return (
    <Box className={classes.container}>
      {recurrenceDates.map((event, idx) => {
        return (
          <Grid container spacing={2} key={idx} direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs>
              <DatePicker
                inputFormat={DH.getDateFormat()}
                renderInput={(params) => <TextField variant="standard" {...params} error={errorMinDate} />}
                minDate={dayjs.unix(minDate)}
                onError={(reason) => {
                  if (reason === 'minDate') {
                    setErrorMinDate(true)
                  }
                }}
                onChange={(d: Dayjs) => d && onUpdate(idx, d.unix())}
                value={dayjs.unix(event.start)}
                className={classes.listItem}
              />
              {errorMinDate && <FormHelperText>{t('Not before original event')}</FormHelperText>}
            </Grid>
            <Grid item xs>
              {allDay ? null : (
                <TimePicker
                  ampm={DH.isTimeFormat12h()}
                  renderInput={(params) => <TextField variant="standard" {...params} />}
                  onChange={(d: Dayjs) => d && onUpdate(idx, d.unix())}
                  value={dayjs.unix(event.start)}
                  className={classes.listItem}
                />
              )}
            </Grid>
            <Grid item>
              <IconButton aria-label="delete" onClick={() => onUpdate(idx)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        )
      })}
      <Grid
        container
        direction="row"
        justifyContent={recurrenceDates.length ? 'center' : 'flext-start'}
        alignItems="center"
      >
        <Fab aria-label="add" color="primary" onClick={() => onUpdate()} size="medium" variant="extended">
          <AddIcon /> {t('Add date')}
        </Fab>
      </Grid>
    </Box>
  )
}

export default StaticRecurrenceDatesTable
