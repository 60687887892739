import { Radio, RadioGroup } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmDialog from './ConfirmDialog'
import { RecurrenceUpdateMode } from '../../generated/graphql'

export interface DialogData {
  saveOption: RecurrenceUpdateMode | null
  disableCustomSave?: boolean
  okText?: string
  koText?: string

  setSaveOption(value: RecurrenceUpdateMode): void

  onSave(value?: RecurrenceUpdateMode): void
  onCancel?(): void
}

interface Props extends DialogData {
  open: boolean

  onClose(): void
}

const EventSaveRecurrenceDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  return (
    <ConfirmDialog
      open={props.open}
      onClose={() => {
        props.setSaveOption(undefined)
        props.onClose()
      }}
      title={t('This is a recurrent appointment')}
      text={t('How would you like to save your changes?')}
      onConfirm={() => props.onSave(props.saveOption)}
      okText={t('Save')}
    >
      <RadioGroup
        aria-label="recurrenceUpdateMode"
        name="recurrenceUpdate"
        value={props.saveOption || RecurrenceUpdateMode.Update_All}
        defaultValue={RecurrenceUpdateMode.Update_All}
        onChange={(e) => props.setSaveOption(e.target.value as RecurrenceUpdateMode)}
      >
        <FormControlLabel
          value={RecurrenceUpdateMode.Update_Single}
          control={<Radio />}
          label={t('Just this appointment')}
        />
        <FormControlLabel
          value={RecurrenceUpdateMode.Update_Forward}
          control={<Radio />}
          label={t('This appointment and following ones')}
        />
        <FormControlLabel
          value={RecurrenceUpdateMode.Update_All}
          control={<Radio />}
          label={t('All the appointments in the series')}
        />
      </RadioGroup>
    </ConfirmDialog>
  )
}

export default EventSaveRecurrenceDialog
